<template>
  <div class='ptj'>
    <router-link :to='{ name: "test" }'>
      <button-element>Take the Assessment</button-element>
    </router-link>
  </div>
  <div class='rmv'>Report History</div>
  <template v-if='fetchingResults'>
    <div class='ptj'>
      <progress-element active/>
    </div>
  </template>
  <template
    v-else-if='results?.length > 0'
    v-for='(result, index) in resultsData'
    :key='index'>
    <div class='kzl'>
      <div class='lem'>
        <div class='egj'>{{ result.submittedAt }}</div>
        <div class='yde'>{{ result.level }}</div>
      </div>
      <div class='xpf'>
        <div>
          <div class='szn'>Your Score</div>
          <badge-element class='ixj'
            :color='result.color'>
            {{ result.results[0].score }}/90
          </badge-element>
        </div>
        <div class='tkm'>
          <div class='yia'
            @click='downloadReport(result.id)'>
            <template v-if='downloadingReport && downloadResultId === result.id'>
              <progress-element active/>
            </template>
            <template v-else>
              <i class='icon'>download</i>
              <span class='xfr'>Download</span>
            </template>
          </div>
          <div class='yia'>
            <router-link :to='{ name: "result", params: { id: result.id }, query: { industryId: industryId } }'>
              <span class='xfr'>View report</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class='ptj'>
      <div class='ols'>No results</div>
    </div>
  </template>

  <dialog-element ref='errorDialog'>
    <div class='zfx'>
      <div class='asd'>Error</div>
      <div class='lwv'>{{ errorMessage }}</div>
      <div class='ooh'>
        <button-element
            color='red'
            @click='errorDialog.hide()'>
            Close
          </button-element>
      </div>
    </div>
  </dialog-element>
</template>

<script>
  import { computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import store from '@/store'
  import { EXPORT_REPORT, GET_RESULTS, GET_USER, FETCH_PROFILE } from '@/store/action-types'

  export default {
    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch(GET_USER)
      if (!user || !user.emailVerified) {
        next({ name: 'login' })
        return
      }
      next()
    },

    setup () {
      let store = useStore()

      let downloadResultId = ref(null)
      let downloadingReport = ref(false)
      let errorDialog = ref(null)
      let errorMessage = ref('')
      let fetchingResults = ref(true)
      let resultsData = ref([])
      let industryId = computed(() => store.state.user.industry.id);
      let results = computed(() => store.state.test.results)

      let downloadReport = async (resultId) => {
        if (downloadingReport.value) return
        try {
          downloadingReport.value = true
          downloadResultId.value = resultId
          let response = await store.dispatch(EXPORT_REPORT, resultId)
          if (response.status === 200) {
            let downloadElement = document.createElement('a')

            let result = results.value.find(result => result.id === resultId)
            let filename = `Result-${formatDate(result.createdAt)}`
            downloadElement.setAttribute('download', filename)

            let downloadUrl = window.URL.createObjectURL(response.data)
            downloadElement.href = downloadUrl

            document.body.appendChild(downloadElement)
            downloadElement.click()
            document.body.removeChild(downloadElement)

            setTimeout(() => {
              window.URL.revokeObjectURL(downloadUrl)
            }, 100)
          }
        } catch (error) {
          errorMessage.value = error.message
          errorDialog.value.show()
        } finally {
          downloadingReport.value = false
          downloadResultId.value = null
        }
      }

      let fetchResults = async () => {
        try {
          await store.dispatch(GET_RESULTS)
          if (results.value) {
            resultsData.value = results.value.map(result => {
              return {
                ...result,
                color: mappedColor(result.results[0].score),
                level: mappedLevel(result.results[0].score),
                submittedAt: formatDate(result.createdAt)
              }
            })
          }
        } catch (error) {
          errorMessage.value = error.message
          errorDialog.value.show()
        }
      }

      let formatDate = (createdAt) => {
        let dateObj = new Date(createdAt)
        let date = dateObj.getDate()
        let month = dateObj.toDateString().split(' ')[1]
        let year = dateObj.getFullYear()
        let hour = `${dateObj.getHours() < 10 ? '0' : ''}${dateObj.getHours()}`
        let minute = `${dateObj.getMinutes() < 10 ? '0' : ''}${dateObj.getMinutes()}`
        return `${date} ${month} ${year} ${hour}-${minute}`
      }

      let mappedColor = (score) => {
        if (score <= 30) return 'amber'
        if (score <= 45) return 'orange'
        if (score <= 60) return 'purple'
        if (score <= 75) return 'blue'
        return 'green'
      }

      let mappedLevel = (score) => {
        if (score <= 30) return 'NEWCOMER'
        if (score <= 45) return 'EXPLORER'
        if (score <= 60) return 'CHALLENGER'
        if (score <= 75) return 'PRACTITIONER'
        return 'CHAMPION'
      }
      let fetchUserProfile = async () => {
        if(!industryId.value){
        await store.dispatch(FETCH_PROFILE);
        }
      };
      onMounted(async () => {
        try {
          await Promise.all([
            fetchUserProfile(),
            fetchResults()
          ])
          fetchingResults.value=false
        }catch(e){
          console.error(e)
          fetchingResults.value=false
        }finally {
          fetchingResults.value=false
        }
        
      })

      return {
        downloadingReport,
        downloadReport,
        downloadResultId,
        errorDialog,
        fetchingResults,
        errorMessage,
        results,
        resultsData,
        industryId 
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .ptj {
    text-align: center;
  }

  .rmv {
    font-size: map.get(variables.$font-sizes, 'lg');
    font-weight: map.get(variables.$font-weights, 'bold');
    margin: 24px 0;
  }

  .kzl {
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
  }

  .lem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .xpf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    align-items: flex-end;
  }

  .egj {
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .yde {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
    text-transform: uppercase;
  }

  .szn {
    color: map.get(variables.$colors, 'gray', '500');
  }

  .tkm {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .yia {
    color: map.get(variables.$colors, 'gray', '500');
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .yia + .yia {
    margin-left: 8px;
  }

  .xfr {
    text-decoration: underline;
  }

  .ixj {
    margin-top: 8px;
  }

  .ols {
    color: map.get(variables.$colors, 'gray', '500');
    font-style: italic;
  }

  .zfx {
    padding: 24px;
  }

  .asd {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .lwv {
    margin: 16px 0 24px;
  }

  .ooh {
    text-align: right;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .kzl {
      padding: 24px;
    }

    .zfx {
      padding: 32px;
    }

    .lwv {
      margin: 24px 0 32px;
    }

    .ooh {
      text-align: center;
    }
  }
</style>
